@use "sass:color";

$spacer: 16px;
$enable-shadows: true;
$enable-gradients: true;
$border-radius: 4px;

@import "node_modules/bootstrap/scss/bootstrap";
@import "~highlight.js/styles/github.css";

        html, body {
background: var(--pageBackgroundColor);
        }


body {
    overflow-y: scroll;
}


.App > header {
  height: 64px;
  background: var(--mainBackgroundColor);
  color: var(--mainTextColor);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
}


.card {
  background: var(--mainBackgroundColor);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  border: 0;
  overflow: hidden;
}

.modal-content {
  background: var(--modalBackgroundColor);
  color: var(--mainTextColor);
}

.create-new-dweet:hover {
    text-decoration: underline;
}

.plus-icon {
    display: inline-block;
    width: 21px;
    height: 21px;

    // Trick to make a plus sign with CSS:
    background:
        linear-gradient(var(--primary), var(--primary)),
        linear-gradient(var(--primary), var(--primary)),
        white;

    background-position: center;
    background-size: 60% 3px,3px 60%;
    background-repeat:no-repeat;
    color: var(--primary);
    border-radius: 10px;
    margin-right: 10px;
}

@media (max-width: 660px) {
    .create-new-dweet-label {
        display: none;
    }
}

@media (max-width: 750px) {
    .create-dweet-label {
        display: none;
    }
}

@media (max-width: 660px) {
    .plus-icon {
        margin: 0;
    }
}

@media (max-width: 680px) {
    header .settings-dropdown .username-text {
        display: none;
    }
}

input.form-control,
input.form-control[readonly] {
    background: var(--secondaryBackgroundColor);
    color: var(--mainTextColor);
    border: 0;
    border-bottom: 1px solid var(--secondaryBorderColor);
    outline: 0 !important;
}

input.form-control:focus {
    background: var(--focusedTextInputBackgroundColor);
    color: var(--focusedTextInputTextColor);
}



header .dropdown > button, 
header > div,
header .dropdown {
    height: 64px !important;
}

header .dropdown div > button {
    height: 48px !important;
}

header button,
header button.focus,
header button:focus {
    background: transparent !important;
    color: var(--mainTextColor) !important;
    border: 0 !important;
    outline: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

header button:focus {
    background: var(--secondaryBackgroundColor) !important;
}

.dropdown-menu {
    border: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    background: var(--secondaryBackgroundColor);
}

header .dropdown-menu.right {
    right: 0 !important;
    left: auto !important;
    top: 64px !important;
    transform: none !important;
}

header a.dropdown-item {
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    color: var(--blue);

    &:hover {
    color: var(--blue);
        background: var(--secondaryBackgroundColor);
    }
}

.no-link-styling,
.no-link-styling:hover,
.no-link-styling:focus,
.no-link-styling:visited,
.no-link-styling:active {
    color: inherit;
    text-decoration: none;
}

.shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
}

.shadow-primary {
    box-shadow: 0 2px 4px rgba($blue, 0.5) !important;
}

.border-radius {
    border-radius: $border-radius;
}

header button {
    color: var(--mainTextColor) !important;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(127, 127, 127, 0.3);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(127, 127, 127, 0.5);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--mainTextColor);
}

a.no-link-color {
    color: inherit;
}

/* dweet creation responsive layout wrap */
#new-dweet .dweet-view-col,
#new-dweet .dweet-editor-col {
    min-width: 520px;
}

@media (max-width: 1100px) {
    #new-dweet {
        max-width: 640px;
    }
}

@media (max-width: 640px) {
    #new-dweet {
        max-width: 100%;

        .dweet-view-col,
        .dweet-editor-col {
            min-width: 100%;
        }
    }
}

.show-on-parent-hover {
    display: none;
}

.hover-parent:hover .show-on-parent-hover {
    display: flex;
}
